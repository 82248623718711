import { useMutation } from '@apollo/react-hooks';
import 'cropperjs/dist/cropper.css';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import Cropper from 'react-cropper';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { Button, Card, Dialog, Portal } from 'react-native-paper';
import CardTitle from 'shared/components/CardTitle';
import useConfirmModal from 'shared/hooks/useConfirmModal';
import { colors } from 'shared/styles';
import uuid from 'uuid/v4';

interface Props {
  showCircle: boolean;
  title: string;
  path: string;
  onDismiss(): void;
  onUpload(path: String): void;
  width: number;
  height: number;
}

const SIGNED_URL_MUTATION = gql`
  mutation CreateSignedSource($file: String!) {
    signedPublicUrl(file: $file) {
      uploadUrl
    }
  }
`;

const imageToBlob = (imageFile: any) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      const canvas = document.createElement('canvas') as any;
      var ctx = canvas.getContext('2d');
      // @ts-ignore
      canvas.height = this.naturalHeight;
      // @ts-ignore
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      canvas.toBlob((blob: any) => resolve(blob), 'image/jpeg', 0.99);
    };
    img.src = imageFile;
  });
};

const ImageUploadModal: React.FC<Props> = ({
  showCircle,
  title,
  path,
  onDismiss,
  onUpload,
  width,
  height,
}) => {
  const confirmWith = useConfirmModal();

  const [bgColor, setBgColor] = useState<string>(colors.white);
  const [image, setImage] = useState<string>();
  const [cropper, setCropper] = useState<any>();
  const [uploadId] = useState(uuid());

  const getCropData = () => {
    return new Promise((resolve) => {
      cropper
        .getCroppedCanvas({ fillColor: bgColor, width, height })
        .toBlob((blob: any) => resolve(blob), 'image/jpeg');
    });
  };

  const [saving, setSaving] = useState<boolean>(false);

  const [createSignedUrl] = useMutation(SIGNED_URL_MUTATION);

  const displayError = (message: string) => {
    confirmWith({ title: 'Error', message, hideCancel: true });
  };

  const validateImage = () => {
    const errors = [];
    if (!image) errors.push("Image can't be blank");

    return errors;
  };
  const saveImage = async () => {
    const validationErrors = validateImage();
    if (validationErrors.length > 0) {
      displayError(validationErrors.join('\n'));
      return;
    }
    if (saving) return;
    setSaving(true);

    const uploadFile = async (fileName: string, blob: any) => {
      const {
        data: {
          signedPublicUrl: { uploadUrl },
        },
      } = await createSignedUrl({ variables: { file: fileName } });

      const headers = new Headers();
      headers.append('Content-Type', 'image/jpeg');

      await fetch(uploadUrl, {
        method: 'PUT',
        headers: headers,
        body: blob,
      });
    };

    try {
      const slug = path;
      const file = `${slug}/${uploadId}.jpg`;
      const fileOriginal = `${slug}/${uploadId}-original.jpg`;

      const originalBlob = await imageToBlob(image);
      const croppedBlob = await getCropData();

      await uploadFile(fileOriginal, originalBlob);
      await uploadFile(file, croppedBlob);

      setSaving(false);
      onUpload(`/${file}`);
    } catch (error) {
      setSaving(false);
      if (error) {
        // @ts-ignore
        displayError(error.message);
      }
    }
  };

  return (
    <Portal>
      <Dialog visible onDismiss={onDismiss} style={styles.dialog}>
        <Dialog.Title>{title}</Dialog.Title>
        <ScrollView
          keyboardDismissMode="on-drag"
          contentInsetAdjustmentBehavior="automatic"
          style={styles.container}
        >
          <Card style={styles.cardContainer}>
            <CardTitle
              style={{ marginVertical: 10 }}
              left={(props) => (
                <TouchableOpacity activeOpacity={0.7} onPress={() => {}}>
                  <div
                    className="img-preview"
                    style={{
                      width: (width / height) * 50,
                      height: 50,
                      borderRadius: showCircle ? '50%' : undefined,
                      overflow: 'hidden',
                      backgroundColor: bgColor,
                    }}
                  />
                </TouchableOpacity>
              )}
            />

            <Card.Content>
              <input
                type="file"
                accept=".jpg,.png"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const reader = new FileReader();
                    const file = e.target.files[0];

                    reader.addEventListener('load', (e) => {
                      setImage(reader.result as any);
                    });
                    reader.readAsDataURL(file);
                  }
                }}
              />

              <label style={{ marginTop: 10, marginBottom: 10 }}>
                <input
                  type="color"
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                  style={{ marginRight: 5 }}
                />
                Background color: {bgColor}
              </label>

              <Cropper
                style={{
                  height: (height / width) * (showCircle ? 400 : 600),
                  width: showCircle ? 400 : 600,
                  backgroundColor: bgColor,
                }}
                aspectRatio={width / height}
                preview=".img-preview"
                src={image}
                viewMode={0}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={false}
              />
            </Card.Content>

            <Card.Actions style={styles.cardActionsContainer}>
              <Button
                mode="outlined"
                onPress={() => {
                  onDismiss();
                }}
              >
                CANCEL
              </Button>
              <Button
                icon="content-save"
                mode="contained"
                onPress={() => {
                  saveImage();
                }}
                loading={saving}
              >
                SAVE
              </Button>
            </Card.Actions>
          </Card>
        </ScrollView>
      </Dialog>
    </Portal>
  );
};

const styles = StyleSheet.create({
  dialog: { alignSelf: 'center', height: '80vh', width: '80vw', maxWidth: 768 },
  container: {
    backgroundColor: '#eee',
    flex: 1,
  },
  cardContainer: {
    margin: 10,
  },
  cardActionsContainer: {
    justifyContent: 'space-evenly',
  },
});

export default ImageUploadModal;
