import { useQuery } from '@apollo/react-hooks';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import gql from 'graphql-tag';
import useCurrentUser from 'hooks/useCurrentUser';
import moment from 'moment';
import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import { ProgressBar } from 'react-native-paper';
import ErgIcon from 'shared/components/ErgIcon';
import { HRZones, hrColors } from 'shared/components/metrics';
import { colors, darkTheme, fonts } from 'shared/styles';
import { ErgType } from 'shared/types';
import {
  calculatePace,
  calculatePower,
  ergTypeDisplay,
  formatTime,
  humanizeDistance,
} from 'shared/utils';
import { muiThemeAttributes } from 'utils/helpers';
import { isMobileApp, postMobileMessage } from 'utils/mobile';
import { useHistory } from 'react-router-dom';

const STATS_FRAGMENT = gql`
  fragment StatsFragment on UserStats {
    totalDistance
    totalDays
    totalTime
    totalCalories
    weightedDistance
    hrZones
    rates
  }
`;

const GET_STATS = gql`
  query GetStats(
    $time: String!
    $ergTypes: [String]
    $skipPreviousStats: Boolean!
  ) {
    userStats: myStats(time: $time, ergTypes: $ergTypes) {
      ...StatsFragment
      recentStats {
        name
        image
        total
      }
    }
    previousStats: myStats(
      time: $time
      previousPeriod: true
      ergTypes: $ergTypes
    ) @skip(if: $skipPreviousStats) {
      ...StatsFragment
    }
  }
  ${STATS_FRAGMENT}
`;

interface UserStats {
  totalDistance: number;
  totalDays: number;
  totalTime: number;
  totalCalories: number;
  weightedDistance: number;
  hrZones: number[];
  rates: number[];
  recentStats: Stat[];
}

interface Stat {
  name: string;
  total: number;
  image: string;
}

interface QueryData {
  userStats: UserStats;
  previousStats: UserStats;
}

const Row = forwardRef(
  ({ stat, percentage }: { stat: Stat; percentage: number }, ref) => {
    // @ts-ignore
    const styles = useStyles();
    return (
      <div
        className={styles.row}
        // @ts-ignore
        ref={ref}
      >
        <div
          className={styles.bar}
          style={{
            backgroundColor: 'rgba(255,255,255, 0.2)',
            width: `${75 * percentage}%`,
          }}
        >
          <img
            alt={`${stat.name} logo`}
            className={styles.image}
            src={stat.image}
          />
        </div>
        <div className={styles.rowMetricContainer}>
          <div className={styles.rowName}>{stat.name}</div>
          {humanizeDistance(stat.total)}
        </div>
      </div>
    );
  }
);

const muiTheme = createMuiTheme({
  ...muiThemeAttributes,
  palette: {
    ...muiThemeAttributes.palette,
    type: 'dark',
  },
});

const defaultErgs = [
  ErgType.row,
  ErgType.ski,
  ErgType.bike,
  ErgType.fanBike,
  ErgType.run,
  ErgType.multi,
];

const StatItem = ({
  name,
  children,
  style,
  previousValue,
  newValue,
}: {
  name: string;
  children: ReactNode;
  previousValue?: number;
  newValue?: number;
  style?: any;
}) => {
  // @ts-ignore
  const styles = useStyles();

  const percentage =
    previousValue && newValue
      ? ((newValue - previousValue) / previousValue) * 100
      : 0;
  const isIncrease = percentage > 0;

  return (
    <div className={styles.statItemContainer} style={style}>
      <InputLabel className={styles.statItemHeader}>{name}</InputLabel>
      {!!percentage && (
        <div className={styles.statItemPercentageHeader}>
          <MaterialIcon
            name={isIncrease ? 'arrow-upward' : 'arrow-downward'}
            size={18}
            color={isIncrease ? green : red}
          />
          {Math.round(Math.abs(percentage))}
          <span style={{ fontSize: 14 }}>%</span>
        </div>
      )}
      <Typography variant="h4" className={styles.statItemValue}>
        {children}
      </Typography>
    </div>
  );
};

const humanizeTime = (value: number) => {
  const duration = moment.duration(value * 1000);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  const values = [];

  if (years) {
    values.push(`${years}y`);
    values.push(`${months}m`);
    values.push(`${days}d`);
  } else if (months) {
    values.push(`${months}m`);
    values.push(`${days}d`);
  } else if (days) {
    values.push(`${days}d`);
    if (hours) values.push(`${hours}h`);
  } else {
    if (hours) values.push(`${hours}h`);
    if (minutes) values.push(`${minutes}m`);
  }

  return values.join(' ');
};

const ERG_RATES = [ErgType.row, ErgType.ski, ErgType.bike, ErgType.fanBike];
const displayRates = (rates: number[]) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {rates.map((rate, index) => {
        return rate ? (
          <div
            key={index}
            style={{
              border: '1px solid rgba(255,255,255, 0.1)',
              borderRadius: 5,
              padding: 4,
              paddingBottom: 2,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: index === rates.length - 1 ? 0 : 10,
              fontSize: '0.66em',
            }}
          >
            <ErgIcon
              style={{ marginTop: -10 }}
              ergType={ERG_RATES[index]}
              size={15}
              color="white"
            />
            {Math.round(rate)}
          </div>
        ) : null;
      })}
    </div>
  );
};

const getYears = (insertedAt: string) => {
  const start = moment(insertedAt).year();
  const end = moment().year();
  return Array.from(Array(end - start + 1).keys())
    .map((i) => start + i)
    .reverse();
};

const green = hrColors[2];
const red = hrColors[4];

const UserStatsPage = () => {
  // @ts-ignore
  const styles = useStyles();
  const [time, setTime] = useState('30-day');
  const [ergTypes, setErgTypes] = useState(defaultErgs);
  const { currentUser } = useCurrentUser();

  const variables = {
    time,
    skipPreviousStats: time === 'lifetime',
    ergTypes: ergTypes.length === 0 ? defaultErgs : ergTypes,
  };

  const { data, loading } = useQuery<QueryData>(GET_STATS, {
    fetchPolicy: 'network-only',
    variables,
  });

  useEffect(() => {
    // @ts-ignore
    document.body.style.backgroundColor = bgColor;

    setTimeout(() => postMobileMessage('loaded'), 100);
  }, []);

  const stats = data ? data.userStats.recentStats : [];
  const currentStats = buildStats(data ? data.userStats : undefined);
  const previousStats = buildStats(data ? data.previousStats : undefined);

  const onErgChange = (event: any) => {
    const erg = event.target.name;
    ergTypes.includes(erg)
      ? setErgTypes(ergTypes.filter((t) => t !== erg))
      : setErgTypes([...ergTypes, erg]);
  };

  const firstMetric = stats[0] ? stats[0].total : 1;
  const years = getYears(currentUser.insertedAt);

  const history = useHistory();

  return (
    <ThemeProvider theme={muiTheme}>
      {loading && (
        <ProgressBar style={{ position: 'absolute', top: 0 }} indeterminate />
      )}
      <div className={styles.centerContainer}>
        <div className={styles.container}>
          {!isMobileApp() && (
            <div className={styles.arrowContainer}>
              <IconButton
                onClick={() => history.goBack()}
                style={{ marginRight: 20 }}
              >
                <MaterialIcon name="arrow-back" color="white" size={24} />
              </IconButton>

              <h2>My Stats</h2>
            </div>
          )}
          <div className={styles.splitContainer}>
            <div className={styles.filterContainer}>
              <FormGroup
                style={{
                  flex: 1,
                  marginBottom: 20,
                  flexDirection: 'row',
                }}
              >
                {defaultErgs.map((ergType) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ergTypes.includes(ergType)}
                        onChange={onErgChange}
                        name={ergType}
                      />
                    }
                    label={ergTypeDisplay(ergType)}
                  />
                ))}
              </FormGroup>
              <FormControl variant="outlined" style={{ width: '100%' }}>
                <InputLabel id="timeframe-label">Timeframe</InputLabel>
                <Select
                  labelId="timeframe-label"
                  value={time}
                  onChange={(event) => setTime(event.target.value as string)}
                  label="Timeframe"
                >
                  <MenuItem value="1-day">Last 1 day</MenuItem>
                  <MenuItem value="7-day">Last 7 days</MenuItem>
                  <MenuItem value="30-day">Last 30 days</MenuItem>
                  <MenuItem value="365-day">Last 365 days</MenuItem>
                  {years.map((year) => (
                    <MenuItem key={year} value={`year-${year}`}>
                      {year}
                    </MenuItem>
                  ))}
                  <MenuItem value="lifetime">Lifetime</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ flex: 1 }}>
              <div className={styles.statItemsContainer}>
                <StatItem
                  name="Total Meters"
                  previousValue={previousStats.totalDistance}
                  newValue={currentStats.totalDistance}
                >
                  {currentStats.totalDistance
                    ? humanizeDistance(currentStats.totalDistance)
                    : '-'}
                </StatItem>
                <StatItem
                  name="Total Time"
                  previousValue={previousStats.totalTime}
                  newValue={currentStats.totalTime}
                >
                  {currentStats.totalTime
                    ? humanizeTime(currentStats.totalTime)
                    : '-'}
                </StatItem>
                <StatItem
                  name="Active Days"
                  previousValue={previousStats.totalDays}
                  newValue={currentStats.totalDays}
                >
                  {currentStats.totalDays ? currentStats.totalDays : '-'}
                </StatItem>
                <StatItem
                  name="Total Cals"
                  previousValue={previousStats.totalCalories}
                  newValue={currentStats.totalCalories}
                >
                  {currentStats.totalCalories
                    ? humanizeDistance(currentStats.totalCalories, {
                        meters: '',
                      })
                    : '-'}
                </StatItem>
                <StatItem
                  name="Avg Pace"
                  previousValue={
                    previousStats.pace && calculatePower(previousStats.pace)
                  }
                  newValue={
                    currentStats.pace && calculatePower(currentStats.pace)
                  }
                >
                  {currentStats.pace
                    ? formatTime(currentStats.pace, true)
                    : '-'}
                </StatItem>
                <StatItem
                  name="Avg Watts"
                  previousValue={
                    previousStats.pace && calculatePower(previousStats.pace)
                  }
                  newValue={
                    currentStats.pace && calculatePower(currentStats.pace)
                  }
                >
                  {currentStats.pace
                    ? calculatePower(currentStats.pace).toFixed(0)
                    : '-'}
                </StatItem>
                <StatItem name="Avg Rate" style={{ paddingBottom: 0 }}>
                  {currentStats.rates.reduce((a, b) => a + b, 0) > 0
                    ? displayRates(currentStats.rates)
                    : '-'}
                </StatItem>
                <StatItem name="Avg HR Zones">
                  {currentStats.hrZones.reduce((a, b) => a + b, 0) > 0 ? (
                    <div style={{ display: 'flex' }}>
                      <div style={{ transform: 'scale(1.5)' }}>
                        <HRZones
                          currentZone={currentStats.hrZones.indexOf(
                            Math.max(...currentStats.hrZones)
                          )}
                          fontSize={20}
                          hrZones={currentStats.hrZones}
                          theme={darkTheme}
                        />
                      </div>
                    </div>
                  ) : (
                    '-'
                  )}
                </StatItem>
              </div>

              <div className={styles.rowsContainer}>
                {stats.map((stat) => (
                  <Row
                    key={stat.name}
                    stat={stat}
                    percentage={stat.total / firstMetric}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const bgColor = colors.darkBackground;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerContainer: {
      width: '100%',
      background: bgColor,
      height: '100vh',
      overflow: 'auto',
    },
    arrowContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    container: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      color: 'white',
      maxWidth: 1536,
    },
    splitContainer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    formLabel: {
      color: 'white',
      ...theme.typography.h6,
    },
    filterContainer: {
      width: 228,
      paddingRight: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        paddingRight: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        marginBottom: theme.spacing(4),
      },
    },
    rowsContainer: {
      flex: 1,
      position: 'relative',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    row: {
      display: 'flex',
      margin: theme.spacing(1),
    },
    bar: {
      display: 'flex',
      flexShrink: 0,
      padding: theme.spacing(1),
      borderRadius: theme.spacing(1),
      minWidth: theme.spacing(6),
      justifyContent: 'flex-end',
      transition: 'width 0.4s',
      boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    },
    image: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      objectFit: 'cover',
      borderRadius: '50%',
      boxShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
    },
    rowMetricContainer: {
      minWidth: 0,
      marginLeft: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    rowName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 700,
    },
    statItemsContainer: {
      display: 'grid',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
      },
      gridTemplateColumns: 'repeat(4,minmax(0,1fr))',
      marginBottom: theme.spacing(4),
      gap: '1.2rem',
    },
    statItemContainer: {
      position: 'relative',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      border: '1px solid rgba(255,255,255, 0.3)',
      borderRadius: theme.shape.borderRadius,
    },
    statItemHeader: {
      position: 'absolute',
      top: -theme.spacing(1),
      left: theme.spacing(1),
      paddingLeft: 2,
      paddingRight: 2,
      backgroundColor: bgColor,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    statItemPercentageHeader: {
      fontFamily: fonts.regular.fontFamily,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: -theme.spacing(1.4),
      right: theme.spacing(0.5),
      paddingLeft: 2,
      paddingRight: 2,
      backgroundColor: bgColor,
      whiteSpace: 'nowrap',
    },
    statItemValue: {
      fontWeight: 800,
    },
  })
);

function buildStats(userStats?: UserStats) {
  const totalDistance = userStats ? userStats.totalDistance : 0;
  const totalDays = userStats ? userStats.totalDays : 0;
  const totalTime = userStats ? userStats.totalTime : 0;
  const totalCalories = userStats ? userStats.totalCalories : 0;
  const hrZones = userStats ? userStats.hrZones : [];
  const rates = userStats ? userStats.rates : [];
  const pace = userStats
    ? calculatePace(
        userStats.totalTime,
        userStats.weightedDistance,
        ErgType.row
      )
    : 0;
  return {
    totalDistance,
    totalTime,
    totalDays,
    totalCalories,
    pace,
    rates,
    hrZones,
  };
}

export default UserStatsPage;
