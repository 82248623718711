import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import { SVG_ICONS } from 'shared/SvgIcons';

export const IconButton = ({
  onPress,
  style,
  icon,
}: {
  icon:
    | 'close'
    | 'settings'
    | 'view-grid'
    | 'view-sequential'
    | 'view-aggregated'
    | 'format-font-size-decrease'
    | 'format-font-size-increase';
  onPress(): void;
  style?: ViewStyle | false;
}) => {
  const theme = useTheme();
  const Icon = SVG_ICONS[icon];
  return (
    <TouchableRipple onPress={onPress} style={[style, styles.iconButton]}>
      <Icon fill={theme.colors.text} />
    </TouchableRipple>
  );
};

const styles = StyleSheet.create({
  iconButton: {
    overflow: 'hidden',
    width: 36,
    height: 36,
    borderRadius: 36 / 2,
    margin: 6,
    padding: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
